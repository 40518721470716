.container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding: 15px 17px;
    margin-bottom: 4px;
    background-color: transparent;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .backgroundShape1, 
  .backgroundShape2 {
    position: absolute;
    border-radius: 50%;
    opacity: 0.15;
    z-index: -1;
  }
  
  .backgroundShape1 {
    top: -20px;
    left: -20px;
    width: 100px;
    height: 100px;
    background-color: #ff6b6b;
    animation: floatAround 15s infinite linear;
  }
  
  .backgroundShape2 {
    bottom: -20px;
    right: -20px;
    width: 80px;
    height: 80px;
    background-color: #3caea3;
    animation: floatAround 15s infinite linear;
    animation-delay: -7s;
  }
  
  .animatedText {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .rocket {
    display: inline-block;
    margin-bottom: -2px;
    margin-right: 8px;
  }
  
  .newLook {
    font-size: 0.8rem;
    font-weight: bold;
    /* color: #ff6b6b; */
    color: red;
    margin-bottom: 2px;
    animation: fadeInUp 1.2s forwards;
  }
  
  .comingSoon {
    font-size: 0.8rem;
    font-weight: bold;
    color: #092e79;
    position: relative;
    animation: fadeInUp 1.2s forwards;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes floatAround {
    0% {
      transform: rotate(0deg) translateX(0) translateY(0);
    }
    50% {
      transform: rotate(180deg) translateX(15px) translateY(-15px);
    }
    100% {
      transform: rotate(360deg) translateX(0) translateY(0);
    }
  }